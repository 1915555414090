import React, { useState, useEffect } from 'react'; // Ensure useState is imported
import { Link } from 'react-router-dom';

const AuthTemplate = () => {
  const [userData, setUserData] = useState(null); // State to store user data
  useEffect(() => {
    // Fetch user data from localStorage
    const user = localStorage.getItem('user');
    if (user) {
      try {
        const parsedUserData = JSON.parse(user); // Parse the user data from localStorage
        console.log('Parsed user data:', parsedUserData); // Log parsed user data
        setUserData(parsedUserData); // Set user data state
      } catch (error) {
        console.error('Failed to parse user data from localStorage', error); // Handle parsing error
      }
  }
}, []); // Ensure to use an empty dependency array for one-time fetch

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-sm-6">
          <div className="card_dashboard">
            <h4 data-toggle="modal" data-target="#discai_how_to_use"> 
              Navigating the DiscAI <br /> (how to use) 
            </h4>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="card_dashboard">
            <h4 data-toggle="modal" data-target="#disc_assessments"> 
              Extended disc assessments <br /> VS other assessments 
            </h4>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="card_dashboard">
            <h4 data-toggle="modal" data-target="#disc_action"> 
              DISC Styles In Action 
            </h4>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="card_dashboard">
            <h4 data-toggle="modal" data-target="#flexing_your_style"> 
              Flexing your Style 
            </h4>
          </div>
        </div>
        <div className="categories-container">                    
          <div className="col-md-3 col-sm-6">
            <a href="/resource-page" className="card_dashboard">
              <h4>Resources</h4>
            </a>
          </div>
          <div className="col-md-3 col-sm-6">
            <a href="/how-we-work" className="card_dashboard">
              <h4>How We Work</h4>
            </a>
          </div>
          <div className="col-md-3 col-sm-6">
            <a href="/quick-coaching" className="card_dashboard">
              <h4>Quick Coaching</h4>
            </a>
          </div>
        </div>
      </div>

      <section className="assessments_listing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="heading_assessments">
                <h5> List of Assessments </h5>
              </div>
            </div>
            <div className="col-md-6">
              <form className="form-inline search_form_right my-2 my-lg-0">
                <input className="form-control ml-auto" type="search" placeholder="Search" />      
              </form>
            </div>
          </div>

          <div className="row"> 
            <div className="col-md-3">
              <div className="outer_list_icon">
                <div className="icon_assensment">
                  <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/Icon_list.svg" alt="icon_list" />
                </div>
                <div className="name_assensment">
                  <h5> Date of Assessment </h5>
                  <p>{userData?.data?.report_date}</p>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="detail_assesment">
                <p>{userData?.data?.report_name}</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="outer_list_icon last_icons">
                <div className="icon_assensment">
                <Link to="/report" title="View Report">
                    <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/eyedash.svg" alt="View Report" />
                </Link>
                </div>  
                <div className="icon_assensment">
                  <a href="/generate-pdf/" title="Download Report">
                    <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/dashbaord_download.svg" alt="Download Report" style={{ maxWidth: '50px' }} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     
    </div>
  );
};

export default AuthTemplate;
