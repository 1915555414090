// src/pages/NewLanding.js
import React from 'react';

const NewLanding = () => {
  return (
    <div>
      <h2>New Landing Page</h2>
      <p>This is the new landing page.</p>
    </div>
  );
};

export default NewLanding;

