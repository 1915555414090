// src/pages/Home.js
import React from 'react';

const Home = () => {
  return (
    <section class="elementor-section elementor-top-section elementor-element elementor-element-4af9e93f elementor-section-height-min-height videovs_main elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="4af9e93f" data-element_type="section" id="home" data-settings="{&quot;background_background&quot;:&quot;video&quot;,&quot;background_video_link&quot;:&quot;http:\/\/extendeddiscsolutions.org\/wp-content\/uploads\/2022\/10\/pexels-diva-plavalaguna-6985519-1.mp4&quot;,&quot;background_play_on_mobile&quot;:&quot;yes&quot;}">

<div class="elementor-background-overlay"></div>
<div class="elementor-container elementor-column-gap-default">
<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5969cf55" data-id="5969cf55" data-element_type="column">
<div class="elementor-widget-wrap elementor-element-populated">
<div class="elementor-element elementor-element-6994c78d elementor-widget elementor-widget-heading animated fadeInLeft" data-id="6994c78d" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="heading.default">
<div class="elementor-widget-container">
<h2 class="elementor-heading-title elementor-size-default">Cultivate Excellence with AI-Enhanced Behavioural Assessments </h2>		</div>
</div>
<div class="elementor-element elementor-element-6a00037d elementor-widget elementor-widget-heading animated fadeInLeft" data-id="6a00037d" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:100}" data-widget_type="heading.default">
<div class="elementor-widget-container">
<h2 class="elementor-heading-title elementor-size-default">Redefining Recruitment &amp; Selection, Leadership, and Sales Success</h2>		</div>
</div>
<div class="elementor-element elementor-element-27f71b5f elementor-widget elementor-widget-heading animated fadeInLeft" data-id="27f71b5f" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="heading.default">
<div class="elementor-widget-container">
<p class="elementor-heading-title elementor-size-default">Experience a groundbreaking solution that revolutionizes the way you approach recruitment, leadership development, and sales capacity building. Our AI-enhanced Behavioural Assessments unlock unparalleled insights into individuals' behaviour, enabling smarter hiring decisions, personalized leadership growth, and optimized sales strategies. 
</p>		</div>
</div>
<div class="elementor-element elementor-element-fa4efd2 elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button animated fadeInLeft" data-id="fa4efd2" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:300}" data-widget_type="button.default">
<div class="elementor-widget-container">
<div class="elementor-button-wrapper">
<a class="elementor-button elementor-button-link elementor-size-md" href="#get_quote">
<span class="elementor-button-content-wrapper">
<span class="elementor-button-text">Request a Quote</span>
</span>
</a>
</div>
</div>
</div>
</div>
</div>
<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-22d0a9bc" data-id="22d0a9bc" data-element_type="column">
<div class="elementor-widget-wrap">
</div>
</div>
</div>
</section>
  );
};

export default Home;

