// src/pages/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Login = ({ setUser }) => {
  const [identifier, setIdentifier] = useState(''); // Change email to identifier
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Reset error state on new submission

    const trimmedIdentifier = identifier.trim(); // Username
    const trimmedPassword = password.trim(); // Password

    console.log('Trimmed Identifier:', trimmedIdentifier); // Check the username
    console.log('Trimmed Password:', trimmedPassword); // Avoid logging passwords in production

    try {
        const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/custom_login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: trimmedIdentifier, // Change to "email"
                password: trimmedPassword,
            }),
        });

        const data = await response.json();
        console.log('API Response:', data); // Log the full response

        if (response.ok && data.status !== false) {
            localStorage.setItem('user', JSON.stringify(data)); 
            setUser(data); 
            navigate('/dashboard'); 
        } else {
            setError(data.error_message || 'Login failed. Please try again.'); 
        }
    } catch (err) {
        console.error(err); 
        setError('An error occurred. Please try again.');
    }
};

return (
    <div className="form-container">
      <h2>Login</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username Or Email:</label><br />
          <input 
            type="text"  // Change type to text to allow both username and email
            value={identifier} 
            onChange={(e) => setIdentifier(e.target.value)} 
            className="form-input"
            required 
          />
        </div>
        <div>
          <label>Password:</label><br />
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            className="form-input"
            required 
          />
        </div>
        <button type="submit" className="form-button">Login</button>
      </form>
    </div>
  );
};

export default Login;
