// src/components/Header.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = ({ user, setUser }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);  // Clear state
    navigate('/login');  // Redirect to login page
  };

  return (
    <header className="header">
      <h1>
        <img
          width="auto"
          height="auto"
          src="https://www.extendeddiscsolutions.org/wp-content/uploads/2023/05/ED-Logo-160x89-1-126x70.jpg"
          className="attachment-large size-large wp-image-4965"
          alt="Logo"
        />
      </h1>
      <nav>
        <ul>
          {user ? (
            <li>
              <button onClick={handleLogout}>Logout</button>
            </li>
          ) : (
            <>
              <li><Link to="/login">Login</Link></li>
              <li><Link to="/signup">Signup</Link></li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
