// pages/ReportData.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; 


const searchArr = (array, key, value) => {
    const result = [];
    const recurse = (arr) => {
      arr.forEach((item) => {
        if (item[key] === value) {
          result.push(item);
        }
        if (item.fields) {
          recurse(item.fields);
        }
      });
    };
    recurse(array);
    console.log('shiwani'.result);
    return result;
   
};

const ReportData = ({
  reportTitle,      
  reportDate,       
  reportPassword,   
  userName,         
}) => {
  const [DISCContent, setDISCContent] = useState('');
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(true);
 
  // Function to handle API sign-in
  const signIn = async () => {
    try {
      const response = await axios.post('https://finxs.com/api/sign_in', null, {
        params: {
          username: 'DISCAI',
          password: '12345678',
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return response.data.token;
    } catch (error) {
      console.error('Error during sign-in:', error);
      throw error;
    }
  };

  // Function to fetch report data
  const fetchReport = async (authToken) => {
    try {
      const response = await axios.get('https://finxs.com/api/dpa/passwords/report', {
        params: {
          auth_token: authToken,
          access_code: 'JAM-SPIKE2',
          password: reportPassword,
          report_name: 'JAM_JSON_DISCAI_Lead',
          report_language: 'AUS',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching report:', error);
      throw error;
    }
  };

  // Function to process fetched report data
  const processReportData = (jsOnArr) => {
    let DISC_Content = '';
    let DISCPerc = [];

    if (jsOnArr.pages && jsOnArr.pages[0]) {
      const arr = jsOnArr.pages[0].fields;
      DISCPerc = searchArr(arr, 'field_name', 'disc_style_with_percentages');
    }

    if (DISCPerc.length > 0) {
      const discArr = {};
      const data = DISCPerc[0].field_data.percentages;
      data.forEach((per, k) => {
        discArr[k] = parseInt(per.replace('%', ''), 10);
      });
      // Sort in descending order
      const sortedDiscArr = Object.entries(discArr).sort((a, b) => b[1] - a[1]);

      // Get top two DISC styles
      if (sortedDiscArr.length >= 2) {
        DISC_Content = sortedDiscArr.slice(0, 2).map((item) => item[0]).join('');
      }
    }

    setDISCContent(DISC_Content);

    // Process report sections
    const processedData = [];
    if (jsOnArr.pages && jsOnArr.pages[0] && jsOnArr.pages[0].fields) {
      jsOnArr.pages[0].fields.forEach((field) => {
        if (field.field_data.competences) {
          processedData.push({
            name: field.field_data.name,
            competences: field.field_data.competences.map((comp) => {
              let perVal = comp.match ? comp.match : 0;
              const competenceNumber = parseInt(comp.person_score, 10);
              const list = [];

              // Negative scores
              for (let i = 5; i > 0; i--) {
                const isSelected = competenceNumber === -i;
                list.push({
                  value: `-${i}`,
                  class: isSelected ? 'slected_li' : '',
                });
              }

              // Positive scores
              for (let i = 0; i <= 5; i++) {
                const isSelected = competenceNumber === i && competenceNumber > 0;
                list.push({
                  value: `${i}`,
                  class: isSelected ? 'slected_li' : '',
                });
              }

              return {
                name: comp.name,
                percVal: perVal,
                list,
              };
            }),
          });
        }
      });
    }

    setReportData(processedData);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const authToken = await signIn();
        const jsOnArr = await fetchReport(authToken);
        processReportData(jsOnArr);
      } catch (error) {
        // Handle errors appropriately
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [reportPassword]);

  if (loading) {
    return <div>Loading...</div>; // Replace with your loader component if any
  }

  return (
    <div id="content">
      <div className="report-content">
        <div className="container-fluid first_report_dowenloadvs">
          <div className="row">
            <div className="col-sm-6">
              <h3>{reportTitle}</h3>
              <h3>Extended Solution Report</h3>
              <p>Date: {new Date().toISOString().split('T')[0]}</p>
            </div>
            <div className="col-sm-6">
              <div className="icon_assensment">
                <a href="/generate-pdf/" title="Download Report">
                  <img
                    src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/dashbaord_download.svg"
                    alt="Download Report"
                    style={{ maxWidth: '50px' }}
                  />
                </a>
              </div>
            </div>
          </div>

          
            <div className="row">
              <div className="col-md-12 col-sm-12 col-lg-12">
                <div className="next_label_outer disc_c_i">
                  <h5>Next Level Challenge</h5>
                  <p>
                    {userName} This Next Level Challenge highlights opportunities for growth,
                    including the identification of blind spots, removal of limiting factors and
                    dealing with potential pitfalls.
                  </p>
                  <p>
                    {userName} You may already have taken steps to address some of these issues,
                    in which case these guidance notes should serve as reinforcement.
                  </p>
                  <p>
                    {userName} These tips are designed to invite you to explore new behaviors and
                    broaden your skill set, ultimately pushing you out of your comfort zone or
                    current actions and facilitating your personal and professional growth.
                  </p>
                  <h5>Accept these challenges to unlock Next Level excellence!</h5>
                  <p>
                    <b>Balance Planning and Spontaneity:</b> Challenge yourself to strike a balance
                    between your natural inclination to plan meticulously and being open to
                    spontaneous opportunities.
                  </p>
                  <p>
                    <b>Delegate Responsibility:</b> Empower others to take charge in specific areas,
                    allowing you to focus on your strengths.
                  </p>
                  <p>
                    <b>Adaptability:</b> Develop your adaptability by consciously embracing change
                    and being less rigid in your approach.
                  </p>
                  <p>
                    <b>Effective Time Management:</b> Work on optimizing your time management
                    skills to maximize productivity without overextending.
                  </p>
                  <p>
                    <b>Networking:</b> Challenge yourself to expand your network, connecting with a
                    diverse range of individuals.
                  </p>
                  <p>
                    <b>Communication Styles:</b> Be adaptable in your communication to resonate with
                    both analytical and expressive personalities.
                  </p>
                  <p>
                    <b>Risk-Taking:</b> Push your boundaries by taking calculated risks, ensuring
                    they align with your goals.
                  </p>
                  <p>
                    <b>Influence Through Data:</b> Use your analytical skills to influence others by
                    presenting data-driven arguments.
                  </p>
                  <p>
                    <b>Conflict Resolution:</b> Learn to address conflicts proactively by using your
                    keen problem-solving abilities.
                  </p>
                  <p>
                    <b>Embrace Ambiguity:</b> Get comfortable with ambiguity and uncertainty, and see
                    them as opportunities to learn and grow.
                  </p>
                  <p>
                    <b>Emotional Intelligence:</b> Enhance your emotional intelligence by
                    understanding and connecting with others on a deeper level.
                  </p>
                  <p>
                    <b>Feedback Reception:</b> Be open to constructive feedback, even if it challenges
                    your well-thought-out plans or your best efforts.
                  </p>
                  <p>
                    <b>Cross-Functional Collaboration:</b> Collaborate with individuals from different
                    departments to gain fresh perspectives.
                  </p>
                  <p>
                    <b>Innovation:</b> Allow yourself to think creatively and explore innovative
                    solutions.
                  </p>
                  <p>
                    <b>Personal Branding:</b> Develop your personal brand to convey both your
                    analytical prowess and charisma.
                  </p>
                  <p>
                    <b>Mentorship:</b> Seek a mentor who can guide you in navigating the delicate
                    balance between Conscientiousness and Influence.
                  </p>
                  <p>
                    <b>Adopt Flexibility:</b> Be flexible in your approach to accommodate the
                    ever-changing business landscape.
                  </p>
                  <p>
                    <b>Storytelling:</b> Enhance your ability to communicate your ideas through
                    compelling stories that resonate with others.
                  </p>
                  <p>
                    <b>Leadership Development:</b> Invest in leadership development that aligns
                    with your unique style, focusing on effectiveness.
                  </p>
                  <p>
                    <b>Self-Reflection:</b> Regularly reflect on your behavior and its impact on
                    others, making adjustments as needed.
                  </p>
                  <h5>Potential Pitfalls and Solutions</h5>
                  <p>
                    <b>Overthinking:</b> The risk of overanalyzing can paralyze you
                  </p>
                  <p>
                    <b>Solution:</b> Set clear decision-making criteria and stick to them.
                  </p>
                  <p>
                    <b>Resistance to Change:</b> Constant questioning and data-gathering and a
                    risk-averse mindset may hinder your adaptability.
                  </p>
                  <p>
                    <b>Solution:</b> Accept the inevitability of change and develop coping mechanisms
                    to address change that comes at a pace with which you are uncomfortable.
                  </p>
                  <p>
                    <b>Perfectionism:</b> The pursuit of perfection may slow you down.
                  </p>
                  <p>
                    <b>Solution:</b> Prioritize tasks and recognize when 'good enough' is sufficient.
                  </p>
                  <p>
                    <b>Difficulty Delegating:</b> Trusting others with tasks can be challenging.
                  </p>
                  <p>
                    <b>Solution:</b> Start with small delegations and gradually build trust. Also, work
                    to empower others.
                  </p>
                  <p>
                    These coaching tips and strategies should help you leverage your unique blend of
                    Conscientiousness and Influence while addressing the potential challenges. Remember,
                    personal growth is an ongoing process, so embrace self-awareness and continuous
                    improvement.
                  </p>
                </div>
              </div>
            </div>
       

          {/* Render Report Sections */}
          {reportData.map((section, index) => (
            <div key={index}>
              <div className="head_section">
                <p>{section.name.charAt(0).toUpperCase() + section.name.slice(1)}</p>
              </div>
              {section.competences.map((competence, idx) => (
                <div key={idx} className="main_report_outer">
                  <div className="left_questions">
                    <p>
                      <span className="name_compta">{competence.name}</span>{' '}
                      {competence.percVal !== 0 && (
                        <span className="perc">({competence.percVal})</span>
                      )}
                    </p>
                  </div>
                  <div className="right_report_points">
                    <ul className="charts">
                      {competence.list.map((item, itemIdx) => (
                        <li key={itemIdx} className={item.class}>
                          <Link to={`/report/5469?comp=${competence.name}`}>{item.value}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReportData;
