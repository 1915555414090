import React, { useState, useEffect } from 'react'; // Ensure useState is imported
import { Link, useNavigate } from 'react-router-dom';
import '../components/Header-admin.css';
import AuthTemplate from '../pages/Authtemplate';

const HeaderAdmin = () => {
  const [userData, setUserData] = useState(null); // State to store user data
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user data from localStorage
    const user = localStorage.getItem('user');
    if (user) {
      try {
        const parsedUserData = JSON.parse(user); // Parse the user data from localStorage
        console.log('Parsed user data:', parsedUserData); // Log parsed user data
        setUserData(parsedUserData); // Set user data state
      } catch (error) {
        console.error('Failed to parse user data from localStorage', error); // Handle parsing error
      }
    }
  
    const fetchData = async () => {
      const userId = getCurrentUserId(); // Replace with your logic to get the current user ID
      const userMeta = await getUserMeta(userId); // Fetch user metadata
      const userNotifications = await getAllNotifications(userId); // Fetch notifications

      // Set user data and notifications
      setUserData(userMeta);
      setNotifications(userNotifications);
      setNotificationCount(userNotifications.length);
    };

    fetchData(); // Fetch notifications and other user-related data
  }, []); // Ensure to use an empty dependency array for one-time fetch

  const handleLogout = () => {
    localStorage.removeItem('user'); // Clear user data from localStorage
    navigate('/login'); // Navigate to login page
  };

  const handleNotificationRemove = (notificationId) => {
    setNotifications(notifications.filter(notification => notification.id !== notificationId));
    setNotificationCount(notificationCount - 1);
  };

  const profilePictureUrl = 'https://www.extendeddiscsolutions.org/wp-content/uploads/2024/08/contact_us_banner-1.svg'; // Use optional chaining

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="p-2 pt-5">
          <div className="profile_img_outer">
            <div className="pic_profile">
              <div
                className="img logo rounded-circle mb-5"
                style={{ backgroundImage: `url(${profilePictureUrl})` }}
              >
                {/* File input for uploading profile picture */}
                <form style={{ display: 'none' }} method="post" encType="multipart/form-data" className="upload-form">
                  <input type="file" name="profile_picture" accept="image/*" id="file-input" style={{ display: 'none' }} />
                  <label htmlFor="file-input" className="camera-icon">
                    <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/camera.svg" alt="Upload Icon" />
                  </label>
                  <button type="submit" name="upload_profile_picture" className="upload-icon">
                    <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/upload.svg" alt="Upload Icon" />
                  </button>
                  <button type="submit" name="delete_profile_picture" className="delete-icon" onClick={() => { if (!window.confirm('Are you sure you want to delete your profile picture?')) return false; }}>
                    <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/trash.svg" alt="Delete Icon" />
                  </button>
                </form>
              </div>
            </div>
            {/* Update here to display the correct username */}
            <h5>{userData ? userData.data.username || 'Loading...' : 'Loading...'}</h5> {/* Display username */}
          </div>

          <ul className="list-unstyled components mb-5 menu_left_main">
            <li className="active">
              <Link to="/user-profile">Profile</Link>
            </li>
            {userData?.data?.leadership_program === "1" && ( // Updated access to leadership_program
              <li className="menu_left">
                <Link to="/dashboard" className="menu">Leadership Program</Link>
              </li>
            )}
            {userData?.data?.requirement_selection === "1" && (
              <li className="menu_left">
                <Link to="/recruitment-selection" className="menu">Recruitment & Selection</Link>
              </li>
            )}
            {userData?.data?.sales_program === "1" && (
              <li className="menu_left nav-item dropdown">
                <Link className="nav-item nav-link dropdown-toggle" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Sales Success
                </Link>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/selection_recruitment">Recruitment & Selection</Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item active" to="/development">Development</Link>
                </div>
              </li>
            )}
            <li className="menu_left">
              <Link to="https://www.extendeddiscsolutions.org/all-reports/" className="menu">Report</Link>
            </li>
            <li className="logout_left">
              <a href="#" className="logout_btn_head" onClick={handleLogout}>
                Log out <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/logouticonn.svg" alt="logout_icon" />
              </a>
            </li>
          </ul>
        </div>
      </nav>

      {/* Page Content */}
      <div id="content">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <button type="button" id="sidebarCollapse" className="btn btn-primary mr-3">
              <i className="fa fa-bars"></i>
              <span className="sr-only">Toggle Menu</span>
            </button>
            <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/logo_dashboard.png" alt="logo" className="logo_header_top" />
            <ul className="nav navbar-nav ml-auto">
              <li className="nav-item btn_notification_right">
                <a className="nav-link" href="#" id="notification-bell">
                  <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/bel_notification.png" alt="Notification_icon" />
                  <span id="notification-count">{notificationCount}</span>
                </a>
                <div id="notifications-dropdown" style={{ display: 'none' }}>
                  <ul id="notification-list">
                    {notificationCount > 0 ? (
                      notifications.map(notification => (
                        <li className="notification-item" key={notification.id} data-id={notification.id}>
                          <Link to={`/${notification.type}?notification_id=${notification.id}`} className="notification-link">
                            {notification.action}
                          </Link>
                          <button className="remove-notification" onClick={() => handleNotificationRemove(notification.id)} aria-label="Remove notification">&times;</button>
                        </li>
                      ))
                    ) : (
                      <li>No notifications available</li>
                    )}
                    {notificationCount >= 100 && <Link id="see-all" to="#">See All</Link>}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <AuthTemplate />
      </div>
    </div>
  );
};

// Mock functions for fetching user data and notifications (replace with actual API calls)
const getCurrentUserId = () => {
  // Implement logic to get current user ID
  return 1; // Example user ID
};

const getUserMeta = async (userId) => {
  // You can replace this logic with fetching from your API, or return user data from local storage
  const user = localStorage.getItem('user');
  if (user) {
    return JSON.parse(user); // Use the parsed user data directly from localStorage
  }

  // Fallback if no user data found
  return {
    profile_picture: '', // URL of the user's profile picture
    data: {
      username: 'admin_infoserv', // Example username
    },
    leadership_program: '1',
    requirement_selection: true,
    sales_program: true,
  };
};

const getAllNotifications = async (userId) => {
  // Replace with actual fetch call
  return [
    { id: 1, action: 'New message received', type: 'message' },
    { id: 2, action: 'New friend request', type: 'friend_request' },
  ];
};

export default HeaderAdmin;
