import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../components/Header-admin.css'; // Correct path to the Style.css file
import ReportData from '../pages/Report-data';

const ReportView = () => {
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate(); // Move navigate here to be used directly

  useEffect(() => {
    const fetchData = async () => {
      // Simulating fetching user data and notifications from an API
      const userId = getCurrentUserId(); // Replace with your logic to get the current user ID
      const userMeta = await getUserMeta(userId); // Fetch user metadata
      const userNotifications = await getAllNotifications(userId); // Fetch notifications
      
      setUserData(userMeta);
      setNotifications(userNotifications);
      setNotificationCount(userNotifications.length);
    };

    fetchData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUserData({});  // Clear user data
    navigate('/login');  // Redirect to login page
  };

  const handleNotificationRemove = (notificationId) => {
    // Implement your notification removal logic here
    setNotifications(notifications.filter(notification => notification.id !== notificationId));
    setNotificationCount(notificationCount - 1);
  };

  const profilePictureUrl = userData.profile_picture || '/images/user-icon-placeholder-1.png';
  
  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="p-2 pt-5">
          <div className="profile_img_outer">
            <div className="pic_profile">
              <div
                className="img logo rounded-circle mb-5"
                style={{ backgroundImage: `url(${profilePictureUrl})` }}
              >
                {/* File input for uploading profile picture */}
                <form style={{ display: 'none' }} method="post" encType="multipart/form-data" className="upload-form">
                  <input type="file" name="profile_picture" accept="image/*" id="file-input" style={{ display: 'none' }} />
                  <label htmlFor="file-input" className="camera-icon">
                    <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/camera.svg" alt="Upload Icon" />
                  </label>
                  <button type="submit" name="upload_profile_picture" className="upload-icon">
                    <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/upload.svg" alt="Upload Icon" />
                  </button>
                  <button type="submit" name="delete_profile_picture" className="delete-icon" onClick={() => { if (!window.confirm('Are you sure you want to delete your profile picture?')) return false; }}>
                    <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/trash.svg" alt="Delete Icon" />
                  </button>
                </form>
              </div>
            </div>
            <h5>{userData.display_name}</h5>
          </div>

          <ul className="list-unstyled components mb-5 menu_left_main">
            <li className="active">
              <Link to="/user-profile">Profile</Link>
            </li>
            {userData.leadership_program && (
              <li className="menu_left">
                <Link to="/dashboard" className="menu">Leadership Program</Link>
              </li>
            )}
            {userData.requirement_selection && (
              <li className="menu_left">
                <Link to="/recruitment-selection" className="menu">Recruitment & Selection</Link>
              </li>
            )}
            {userData.sales_program && (
              <li className="menu_left nav-item dropdown">
                <Link className="nav-item nav-link dropdown-toggle" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Sales Success
                </Link>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/selection_recruitment">Recruitment & Selection</Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item active" to="/development">Development</Link>
                </div>
              </li>
            )}
            <li className="menu_left">
              <Link to="https://www.extendeddiscsolutions.org/all-reports/" className="menu">Report</Link>
            </li>
            <li className="logout_left">
              <a href="#" className="logout_btn_head" onClick={handleLogout}>
                Log out <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/logouticonn.svg" alt="logout_icon" />
              </a>
            </li>
          </ul>
        </div>
      </nav>

      {/* Page Content */}
      <div id="content">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <button type="button" id="sidebarCollapse" className="btn btn-primary mr-3">
              <i className="fa fa-bars"></i>
              <span className="sr-only">Toggle Menu</span>
            </button>
            <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/logo_dashboard.png" alt="logo" className="logo_header_top" />
            <ul className="nav navbar-nav ml-auto">
              <li className="nav-item btn_notification_right">
                <a className="nav-link" href="#" id="notification-bell">
                  <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/bel_notification.png" alt="Notification_icon" />
                  <span id="notification-count">{notificationCount}</span>
                </a>
                <div id="notifications-dropdown" style={{ display: 'none' }}>
                  <ul id="notification-list">
                    {notificationCount > 0 ? (
                      notifications.map(notification => (
                        <li className="notification-item" key={notification.id} data-id={notification.id}>
                          <Link to={`/${notification.type}?notification_id=${notification.id}`} className="notification-link">
                            {notification.action}
                          </Link>
                          <button className="remove-notification" onClick={() => handleNotificationRemove(notification.id)} aria-label="Remove notification">&times;</button>
                        </li>
                      ))
                    ) : (
                      <li>No notifications available</li>
                    )}
                    {notificationCount >= 100 && <Link id="see-all" to="#">See All</Link>}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>

       {/* Render the AuthTemplate component here */}
       <ReportData />
      </div>
    </div>
  );
};


const getCurrentUserId = () => {
  
};

const getUserMeta = async (userId) => {
  
  return {
    profile_picture: '', 
    display_name: 'John Doe', 
    leadership_program: true, 
    sales_program: true,
    requirement_selection: true
  };
};

const getAllNotifications = async (userId) => {
  
  return [
    { id: 1, type: 'someType', action: 'New message received' },
    { id: 2, type: 'anotherType', action: 'Profile updated' }
  ];
};

export default ReportView;
