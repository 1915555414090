import React, { useState } from 'react'; // Import useState
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Correct imports
import Header from './components/Header';
import Footer from './components/Footer';
import AdminHeader from './components/Header-admin';
import AdminFeader from './components/Footer-admin';
import InnerContent from './components/Report-view';
import Home from './pages/Home';
import NewLanding from './pages/NewLanding';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Report from './pages/Report';
import ReportData from './pages/Report-data';
import logo from './logo.svg'; // If logo is not used, consider removing this import
import './App.css';

function App() {
  // Declare user state
  const [user, setUser] = useState(null); // Initialize user state

  return (
    <Router>
      <Header user={user} setUser={setUser} /> {/* Pass user and setUser as props */}
      <div>
        <Routes>
          <Route path="/login" element={<Login setUser={setUser} />} /> {/* Pass setUser to Login */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/" element={<Home />} />
          <Route path="/new-landing" element={<NewLanding />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/report" element={<Report />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
