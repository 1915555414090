import React, { useState, useEffect } from "react";
import '../components/Style.css'; 
import HeaderAdmin from '../components/Report-view'; 
import '../components/Header-admin.css'; 


const Report = () => {
  useEffect(() => {
    
    document.body.classList.add('dashboard');

    
    return () => {
      document.body.classList.remove('dashboard');
    };
  }, []);

  return (
    <div className="dashboard"> {/* Add a class here for targeting */}
      <HeaderAdmin /> {/* Render the HeaderAdmin component */}
    </div>
  );
};

export default Report;
