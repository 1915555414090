import React, { useEffect, useState } from 'react';
import '../components/Style.css'; 
import HeaderAdmin from '../components/Header-admin'; 
import '../components/Header-admin.css'; 

const Dashboard = () => {
  const [userData, setUserData] = useState(null); // State to store user data

  useEffect(() => {
    document.body.classList.add('dashboard');
    
    // Fetch user data from localStorage
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUserData = JSON.parse(user); // Parse and set user data
      setUserData(parsedUserData);
      console.log(parsedUserData); // Log the user data to the console
    }
  
    return () => {
      document.body.classList.remove('dashboard');
    };
  }, []);
  

  return (
    <div className="dashboard">
      <HeaderAdmin userData={userData} /> {/* Pass userData as a prop */}
    </div>
  );
};

export default Dashboard;
